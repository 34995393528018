<template>
  <label
    class="ReferralSelectorOption"
    :for="`sales_representative_${salesRepresentativeName}`"
    :title="salesRepresentativeName">
    <input
      :id="`sales_representative_${salesRepresentativeName}`"
      :value="salesRepresentative"
      :checked="selectedSalesRepresentative === salesRepresentative"
      type="radio"
      class="Radio"
      required
      @input="onInput">
    <span>
      {{ salesRepresentativeName }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'referral-selector-option',
  props: {
    salesRepresentative: {
      required: true,
      validator: prop => typeof prop === 'object' || prop === null
    },
    selectedSalesRepresentative: {
      required: true,
      validator: prop => typeof prop === 'object' || prop === null
    }
  },
  computed: {
    salesRepresentativeName() {
      if (this.salesRepresentative) {
        return this.salesRepresentative.name
      }
      return this.$t('referralSelectorModal.noOne')
    }
  },
  methods: {
    onInput() {
      this.$emit('input', this.salesRepresentative)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_shared_variables.sass";

.ReferralSelectorOption {
  box-sizing: border-box;
  display: inline-block;
  width: 25%;
  margin-bottom: $space-l;
  padding-left: $space-xs;
  padding-right: $space-xs;
  cursor: pointer;
  word-break: break-word;

  @media (max-width: 767px) {
    width: 50%;
  }
  @media (max-width: 399px) {
    width: 100%;
  }
  input {
    position: absolute;
    margin: 1px 0 0;
  }
  span {
    display: inline-block;
    padding-left: $space-l;
    vertical-align: top;
  }
}
</style>
